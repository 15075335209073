:root {
  --header-height: 82px;
  --bubble-color: #26252a;
  --bubble-color-text: #fff;
  --bubble-height: 34px;
  --blue: #0083f9;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: sans-serif;
  background: #000;
  /* touch-action: none; */
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: none;
}
