.header {
  padding-top: 12px;
  padding-bottom: 12px;
  background: rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 200;

  [data-globalReactsActive="true"] {
    z-index: 1;
  }

  h1 {
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 0px;
    color: #fff;
  }
}

.wrapper {
  margin-top: 12px;
  max-width: 375px;
  margin: 0px auto;
  padding-top: 10px;
  margin-bottom: 72px;
}

.avatarCloud {
  position: relative;
  height: 64px;
  flex-grow: 0;
  flex-shrink: 0;
  background: #f0f;

  .avatar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      width: 100%;
      height: 100%;
      display: block;
    }

    .avatarInner {
      transform: translate(-50%, -50%);
      position: absolute;
      border-radius: 50%;
      height: 64px;
      width: 64px;
      overflow: hidden;
      background: #ddd;
    }

    &:nth-child(1) {
      .avatarInner {
        height: 38px;
        width: 38px;
        top: -13px;
        left: -54px;
      }
    }

    &:nth-child(2) {
      .avatarInner {
        height: 38px;
        width: 38px;
        top: 13px;
        left: 54px;
      }
    }

    &:nth-child(3) {
      .avatarInner {
        height: 24px;
        width: 24px;
        top: 20px;
        left: -46px;
      }
    }

    &:nth-child(4) {
      .avatarInner {
        height: 24px;
        width: 24px;
        top: -20px;
        left: 46px;
      }
    }

    &:nth-child(5) {
      .avatarInner {
        height: 28px;
        width: 28px;
        top: 18px;
        left: -76px;
      }
    }

    &:nth-child(6) {
      .avatarInner {
        height: 28px;
        width: 28px;
        top: -18px;
        left: 76px;
      }
    }

    &:nth-child(7) {
      .avatarInner {
        height: 22px;
        width: 22px;
        top: -8px;
        left: -88px;
      }
    }

    &:nth-child(8) {
      .avatarInner {
        height: 22px;
        width: 22px;
        top: 8px;
        left: 88px;
      }
    }

    &:nth-child(9) {
      .avatarInner {
        height: 18px;
        width: 18px;
        top: 12px;
        left: -102px;
      }
    }

    &:nth-child(10) {
      .avatarInner {
        height: 18px;
        width: 18px;
        top: -12px;
        left: 102px;
      }
    }

    &:nth-child(11) {
      .avatarInner {
        height: 14px;
        width: 14px;
        top: -6px;
        left: -110px;
      }
    }

    &:nth-child(12) {
      .avatarInner {
        height: 14px;
        width: 14px;
        top: 6px;
        left: 110px;
      }
    }
  }
}
