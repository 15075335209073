.addForm {
  border-radius: 16px;
  padding: 24px;
  padding-top: 12px;
  border: 1px solid #333;
  margin-top: 64px;
  margin-left: 12px;
  margin-right: 12px;

  h2 {
    font-size: 14px;
    margin-top: 0px;
    text-align: center;
    font-weight: normal;
    margin-bottom: 18px;
    padding-bottom: 12px;
    margin-left: -24px;
    margin-right: -24px;
    border-bottom: 1px solid #333;
    color: #666;
  }

  select {
    width: 100%;
    padding: 8px;
    height: 36px;
    border-radius: 4px;
    background: #000;
    color: #fff;
    border-color: #333;
  }

  label {
    margin-bottom: 12px;
    display: block;
  }

  textarea {
    width: 100%;
    padding: 8px;
    height: 72px;
    border-radius: 4px;
    font-family: sans-serif;
    background: #000;
    color: #fff;
    border-color: #333;
  }

  .label,
  .note {
    padding-left: 14px;
    padding-top: 4px;
    padding-bottom: 3px;
    font-size: 0.65em;
    color: #888;

    a {
      color: #666;
    }
  }

  .fileInput {
    background: #111;
    color: #fff;
    height: 36px;
    padding: 6px;
    display: flex;
    align-items: center;
  }

  button {
    width: 100%;
    height: 36px;
    padding: 6px;
    text-align: center;
    border: none;
    border-radius: 4px;
    background: #222;
    margin-top: 6px;
    color: #fff;

    &:hover {
      background: #333;
      cursor: pointer;
    }
  }
}

.githubLink {
  text-align: center;
  margin-top: 24px;
  text-decoration: none;
  color: #888;
  display: block;
  font-size: 13px;

  &:hover {
    color: #fff;
  }
}
